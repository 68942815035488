.login {
  .form-login {
    padding: 15px;
    max-width: 500px;
    width: 100%;
    min-width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    input {
      height: 45px;
      font-size: 15px;
    }
    label {
      font-size: 15px;
    }
    #basic_password {
      height: 33px;
    }
    .btn-login {
      button {
        width: 100%;
        height: 35px;
        font-size: 16px;
      }
    }
  }
  .forgotPass {
    color: #1890ff;
    text-align: right;
    margin: -15px 0 15px 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 768px) {
      margin: -7px 0 15px 0;
    }
  }
  .rules {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    a,
    span {
      color: rgba(0, 0, 0, 0.705);
    }
    span:nth-child(2) {
      margin: 0 5px;
    }
  }
}
.tof {
  max-width: 95%;
  margin: 20px auto;
}
