.header {
  position: fixed;
  z-index: 9;
  width: 100%;
}
.anticon-menu-fold {
  display: none !important;
}

@media only screen and (max-width: 566px) {
  .ant-btn-background-ghost {
    margin-top: 0 !important;
  }
  .orgPop-draver {
    display: flex;
    align-items: center;
  }
  .ant-layout-header {
    display: flex;
    justify-content: space-between;
    padding: 0 20px !important;
  }
  .ant-btn-background-ghost {
    float: inherit !important;
  }
  .anticon-menu-fold {
    display: block !important;
    color: #fff !important;
    margin-right: 20px;
    font-size: 16px;
  }
}
.navigation__items {
  @media only screen and (max-width: 566px) {
    display: none !important;
  }
}
.ant-popover,
.ant-popover-placement-bottomRight {
  top: 40px !important;
  // left: 0 !important;
}
.ant-popover::after {
  position: fixed !important;
}
