.sider {
  display: none !important;
  @media only screen and (max-width: 566px) {
    display: flex !important;
  }
}
.logo {
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.drawer-condition {
  .drawerSider {
    display: none;
  }
}
@media only screen and (max-width: 566px) {
  .drawerSider {
    .ant-drawer-close {
      svg {
        fill: #fff;
      }
    }
    .ant-drawer-header-title {
      padding-top: 10px;
      justify-content: flex-end;
    }
    .ant-drawer-header {
    }
    .ant-drawer-header,
    .ant-drawer-body {
      padding: 0;
      background-color: #001529;
    }
    .sider {
      min-width: 100% !important;
      width: 100% !important;
    }
  }
  .drawer-condition {
    .drawerSider {
      display: block;
    }
    .sider-condition {
      display: none;
    }
  }
}

.ant-drawer-content-wrapper {
  width: 240px !important;
}

.ant-layout-sider-children {
  width: 100%;
}

.ant-layout-sider-zero-width-trigger {
  top: 10px;
  right: -40px;
  z-index: 22;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}
