.settings {
  padding: 100px 50px 0px 50px;
  @media screen and (max-width: 768px) {
    padding: 100px 30px 0px 30px;
  }
  .ant-row {
    justify-content: center;
    .ant-col {
      width: 60%;
      @media screen and (max-width: 992px) {
        width: 80%;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        .ant-card-body {
          padding: 20px 10px;
        }
      }
    }
    .ant-tabs-content-holder {
      .ant-form-item-control {
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
    }
  }
}

.ant-list-item {
  cursor: pointer;
}

.ant-list-item:hover {
  color: #1890ff !important;
}

.ant-typography {
  font-weight: 400;
  color: inherit !important;
}