@import "./Head.module";
@import "./StandardFormLayout.module";
@import "./StandardTableLayout.module";
@import "./login";
@import "./setting";

body {
  min-height: 100vh;
}

.site-layout-content,
.ant-card {
  min-height: 400px;
  padding: 15px;
  background: #fff;
  border-radius: 16px !important;
  box-shadow: 0px 0px 17px -2px #0000000a !important;
}

.site-layout-content {
  padding: 30px !important;
}

.ant-card {
}

.clickable {
  cursor: pointer;
}

.ant-spin-spinning {
  opacity: 1;
  display: flex !important;
  justify-content: center;
  height: 300px;
  align-items: center;
  position: absolute !important;
  width: 90%;
}
.modal-sub {
  .ant-spin-spinning {
    height: auto !important;
    position: relative !important;
  }
}
.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
.timeRecoder {
  width: 77%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.document__url {
  display: flex;
}

.lineitems {
  .ant-table-tbody .ant-table-cell {
    width: fit-content;
    height: 50px;
    padding: 0;
    .ant-form-item-control-input-content {
      height: 100% !important;
    }
    .ant-input {
      height: 100% !important;
    }
  }
}
