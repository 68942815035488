.table-layouts {
  .table-lists {
    padding: 62px 50px;
    display: none;
  }
  @media screen and (max-width: 768px) {
    .table-lists {
      display: block;
      padding: 0px 10px;
      .ant-spin {
        height: 200px;
      }
      .ant-card-body {
        padding-top: 0;
        padding-bottom: 0;
      }
      .ant-list-item-meta-title {
        overflow: hidden;
      }
    }
    .tables {
      display: none;
    }
  }
}

.content {
  padding: 62px 50px 20px;
  @media screen and (max-width: 768px) {
    padding: 62px 10px 20px;
  }
}

.comment-timesheet-content {
  padding: 0px 50px 30px;
  @media screen and (max-width: 768px) {
    padding: 0px 10px 30px;
  }
}

.table-time-sheet-admin {
  .ant-layout-content {
    padding-top: 0px;
  }
}
.actionBar {
  height: 30px;
  margin-bottom: 20px;
}
.rightActions {
  float: right;
}

.table-payments .ant-table-container {
  // overflow: hidden !important;
  width: 100%;
  .ant-table-header {
    // overflow-x: hidden !important;
  }
  @media screen and (max-width: 768px) {
    // overflow-x: scroll !important;
    // width: 992px;
  }
}
.table-payments .ant-table {
  // overflow-x: hidden !important;
  @media screen and (max-width: 768px) {
    // overflow-x: scroll !important;
  }
}
.table-timesheet {
  .StandardTableLayout_actionBar__mGy5B {
    height: 10px;
  }
  .ant-table-thead {
    display: none;
  }
}

.table-organisations {
  // .ant-table {
  // overflow-x: scroll;
  // }
  .ant-table-wrapper {
    position: relative;
  }
  .ant-table-container {
    // width: 1620px;
    min-width: 100%;
    table-layout: auto;
  }
  .ant-table-header {
    // overflow-x: auto !important;
    // top: 0 !important;
  }
}
.payroll_sumary {
  .ant-table-cell {
    text-align: center;
  }
}

.team_member {
  @media screen and (max-width: 768px) {
    .ant-table-wrapper {
      position: relative;
    }
    .ant-table {
      overflow: scroll hidden;
    }
    .ant-table-container {
      width: 1620px;
      min-width: 100%;
      table-layout: auto;
    }

    .ant-table-header {
      overflow: auto !important;
      top: 0 !important;
    }
  }
}

.table-view-contact {
  .ant-layout-content {
    padding: 0;
  }
  .ant-breadcrumb {
    margin: 0 !important;
  }
  .site-layout-content {
    padding: 0;
  }
}

.lineitems {
  .ant-table-tbody {
    .ant-table-cell {
      width: 100%;
      height: 100%;
    }
  }
  .ant-table-cell:nth-child(1) {
    width: 2.5%;
  }
  .ant-table-cell:nth-child(2) {
    width: 16.25%;
  }
  .ant-table-cell:nth-child(3) {
    width: 20%;
  }
  .ant-table-cell:nth-child(4) {
    width: 7.5%;
  }
  .ant-table-cell:nth-child(5) {
    width: 7.5%;
  }
  .ant-table-cell:nth-child(6) {
    width: 10%;
  }
  .ant-table-cell:nth-child(7) {
    width: 10%;
  }
  .ant-table-cell:nth-child(8) {
    width: 16.25%;
  }
  .ant-table-cell:nth-child(9) {
    width: 7.5%;
  }
  .ant-table-cell:nth-child(10) {
    width: 2.5%;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-form-item-explain {
    display: none;
  }
}
.rightAlign {
  text-align: right;
  .ant-input-number-handler-wrap {
    display: none;
  }
  input {
    text-align: right;
  }
}

.time-sheet-layout {
  border-radius: 16px;
  box-shadow: 0px 0px 17px -2px #0000000a;
  padding: 30px !important;

  .information-text {
    margin: 24px 0;
    p {
      display: grid;
      grid-template-columns: 120px auto;
    }
  }
}
