.container {
  padding: 55px 24px 0px 21px;
}

.content {
  padding: 62px 50px;
  @media screen and (max-width: 576px) {
    padding: 62px 14px;
  }
}

.ant_card {
  min-height: auto;
}

.dashboard__content {
  padding: 35px 20px;
  .ant-card {
    border-radius: 16px !important;
    box-shadow: 0px 0px 17px -2px #0000000a;
  }
  @media screen and (max-width: 576px) {
    padding: 35px 14px;
  }
}

canvas {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  width: 100% !important;
  min-height: 200px;
}
.list-item__action-list {
  cursor: pointer;
  min-height: 60px;
  &:hover {
    .action-list {
      display: block;
    }
  }
  .action-list {
    display: none;
  }
}
.ant-modal-body {
  padding: 15px;
}
// .totalHoursTime{
//   @media screen and (max-width: 576px) {
//     margin-bottom: 10px !important;
//   }
// }
.form__space {
  width: 50%;
  @media screen and (max-width: 912px) {
    width: 100%;
  }
}
.ant-form-item {
  gap: 10px;
  @media screen and (max-width: 576px) {
    margin-bottom: 10px !important;
  }
}
.signpad_img {
  margin-left: 10%;
  width: 16%;
  @media screen and (max-width: 576px) {
    width: 100%;
    margin-left: 0;
  }
}
.m-signature-pad--footer {
  .btn .btn-default button .clear {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
  }
}
.form-terms {
  .portal {
    display: grid;
    grid-template-columns: 28% 71%;
    justify-content: space-between;
    gap: 8px;
    // @media screen and (max-width: 992px) {
    //   grid-template-columns: 100%;
    // }
  }
}
.form-address {
  .ant-form-item-label > label::after {
    content: "";
  }
  .saveAsPostal {
    p:nth-child(2) {
      font-size: 13px;
      color: #1890ff;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .portal {
    display: grid;
    grid-template-columns: 49% 49%;
    justify-content: space-between;
    gap: 8px;
    @media screen and (max-width: 992px) {
      grid-template-columns: 100%;
    }
  }
}
.view-form {
  margin-top: 26px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1440px) {
    margin-top: 35px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 40px;
  }
  @media screen and (max-width: 678px) {
    margin-top: 50px;
  }
  .ant-page-header {
    margin: 0 -2%;
  }
  @media screen and (max-width: 680px) {
    .ant-descriptions:nth-child(1) {
      .ant-descriptions-row {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
    .ant-descriptions:nth-child(2) {
      tbody {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
.form-contact-view {
  .ant-layout-content,
  .site-layout-content {
    border-radius: 16px;
    box-shadow: 0px 0px 17px -2px #0000000a;
    padding: 0;
  }
  .ant-modal-body {
    padding-top: 10px;
  }
}

.form-fleet {
  @media screen and (max-width: 768px) {
    .ant-list-item {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
    }
    .ant-list-item-action {
      margin: 0;
    }
  }
}
