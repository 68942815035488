.member-form {
  display: grid;
  grid-template-columns: auto auto;

  .personal:nth-child(3),
  .personal:nth-child(4),
  .personal:nth-child(5) {
    grid-column-start: 3;
    grid-column-end: 1;
    @media screen and (max-width: 1420px) {
      .ant-form-item-label {
        flex: 0 0 6%;
        max-width: 6%;
      }
      .ant-col-12 {
        max-width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .ant-col-12 {
      max-width: 100%;
    }
  }
  .ant-form-item {
    display: flex;
      gap: 10px;
      @media screen and (max-width: 1420px) {
      .ant-form-item-label {
        flex: 0 0 27%;
        max-width: 27%;
      }
    }
    @media screen and (max-width: 992px) {
      .ant-form-item-label {
        flex: 0 0 40%;
        max-width: 40%;
      }
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
      .ant-form-item-label {
        flex: 1 !important;
        max-width: 100% !important;
        padding: 0;
      }
    }
  }
}
